import React from 'react';
import {graphql} from 'gatsby';

import Layout from '../components/Layout';

import SEO from '../components/Seo';
import {useI18next} from 'gatsby-plugin-react-i18next';
import FAQ from '../components/Page/FAQ';
import PageHero from '../components/shared/hero';
import WorkWithUs from '../components/Page/home/WorkWithUs';
import {DescriptionBlock} from './cleaning-services-prague';
import ImageContent from '../components/Page/houry-wife/ImageContent';
import {styled} from 'twin.macro';
import {BookButton} from './cleaning-services-prague';
import Reviews from '../components/Page/home/Reviews/Reviews';

const work_with_us = {
  title2: 'hourly_wife_reasons_heading',
  items: [
    {
      title: 'hourly_wife_reasons_item_1_header',
      text: 'hourly_wife_reasons_item_1_content',
    },
    {
      title: 'hourly_wife_reasons_item_2_header',
      text: 'hourly_wife_reasons_item_2_content',
    },
    {
      title: 'hourly_wife_reasons_item_3_header',
      text: 'hourly_wife_reasons_item_3_content',
    },
    {
      title: 'hourly_wife_reasons_item_4_header',
      text: 'hourly_wife_reasons_item_4_content',
    },
    {
      title: 'hourly_wife_reasons_item_5_header',
      text: 'hourly_wife_reasons_item_5_content',
    },
  ],
};

export const Text = styled.p`
  color: black;
  font-family: avertaFont;
  font-style: normal;
  font-weight: 500;
  margin-bottom: 26px;
  line-height: 24px;
  font-size: 15px;

  @media ${props => props.theme.screens.md} {
    font-size: 19px;
    line-height: 31px;
  }
`;

const MarginBottom = styled.div`
  margin-bottom: 0px;
  @media ${props => props.theme.screens.lg} {
    margin-bottom: 100px;
  }
`;

const HourlyWifePage = ({data}) => {
  const {language, t} = useI18next();
  const {
    background,
    backgroundMobile,
    backgroundTablet,
    backgroundWhat,
    backgroundService,
    reviews,
    faq,
  } = data;

  return (
    <Layout headerStyle="homeCleaning">
      <SEO
        title={t('seo_title')}
        description={t('seo_description')}
        lang={language}
        keywords={t('seo_keywords', {returnObjects: true})}
      />
      <PageHero
        content={{
          heading: t(`hourly_wife_hero_heading`),
          text: t(`hourly_wife_hero_description`),
          button: t(`book_cleaning`),
        }}
        imgPosition={'100% 0%'}
        imgMobilePosition={'100% 90%'}
        imgObj={{
          background,
          backgroundMobile: backgroundMobile,
          backgroundTablet: backgroundTablet,
        }}
      />
      <WorkWithUs content={work_with_us} bottomDescription={null} />
      <MarginBottom />
      <DescriptionBlock
        content={t(`hourly_wife_reasons_sub_description`)}
        button_text={t(`book_cleaning`)}
      />
      <ImageContent
        background={backgroundWhat}
        heading={t(`hourly_wife_what_heading`)}
        imgStyle="50% 0%"
      >
        <Text>{t(`hourly_wife_what_description_1`)}</Text>
        <Text>{t(`hourly_wife_what_description_2`)}</Text>
        <BookButton buttonText={t(`book_cleaning`)} />
      </ImageContent>
      <Reviews
        reviews={reviews.edges}
        headerStyle={{marginLeft: 0}}
        heading={t(`hourly_wife_reviews`)}
      />
      <FAQ faqs={faq.edges} headingProps={{marginLeft: 0}} />
      <ImageContent
        background={backgroundService}
        order="image"
        heading={t(`hourly_wife_services_heading`)}
      >
        <Text>{t(`hourly_wife_services_description`)}</Text>
        <BookButton buttonText={t(`book_cleaning`)} />
      </ImageContent>
    </Layout>
  );
};

export default HourlyWifePage;

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(
      filter: {
        language: {eq: $language}
        ns: {in: ["common", "hourly_wife", "seo_hourly_wife", "homepage"]}
      }
    ) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
    background: file(relativePath: {eq: "hourly-wife-hero.png"}) {
      childImageSharp {
        gatsbyImageData(
          width: 1200
          placeholder: NONE
          formats: [AUTO, WEBP, AVIF]
          quality: 90
        )
      }
    }
    backgroundTablet: file(relativePath: {eq: "hourly-wife-tablet.png"}) {
      childImageSharp {
        gatsbyImageData(
          width: 1200
          placeholder: NONE
          formats: [AUTO, WEBP, AVIF]
          quality: 90
        )
      }
    }
    backgroundMobile: file(relativePath: {eq: "hourly-wife-mobile.png"}) {
      childImageSharp {
        gatsbyImageData(
          width: 1200
          placeholder: NONE
          formats: [AUTO, WEBP, AVIF]
          quality: 90
        )
      }
    }
    backgroundWhat: file(relativePath: {eq: "hourly-wife-what.png"}) {
      childImageSharp {
        gatsbyImageData(
          width: 1200
          placeholder: NONE
          formats: [AUTO, WEBP, AVIF]
          quality: 90
        )
      }
    }
    backgroundService: file(relativePath: {eq: "hourly-wife-service.png"}) {
      childImageSharp {
        gatsbyImageData(
          width: 1200
          placeholder: NONE
          formats: [AUTO, WEBP, AVIF]
          quality: 90
        )
      }
    }
    reviews: allContentfulReviews(
      sort: {order: ASC, fields: createdAt}
      filter: {node_locale: {eq: $language}}
    ) {
      edges {
        node {
          comment {
            comment
          }
          rating
          title
          id
          name
          icon {
            gatsbyImageData(
              width: 800
              quality: 90
              layout: CONSTRAINED
              placeholder: BLURRED
              formats: [AUTO, WEBP, AVIF]
            )
          }
        }
      }
    }
    faq: allContentfulFaq(
      sort: {order: ASC, fields: createdAt}
      filter: {pageTag: {eq: "homepage"}, node_locale: {eq: $language}}
    ) {
      edges {
        node {
          childContentfulFaqAnswerTextNode {
            answer
          }
          question
          id
        }
      }
    }
  }
`;
